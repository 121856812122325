import { useQuery } from "react-query";
import supabase from "../supabase";

export async function fetchAllStaff(status) {
  const query = supabase.from("staff").select("*").order("staff_name", { ascending: true });
  
  if (status) {
    query.match({ status });
  }

  const { data, error } = await query;


  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export function useStaff(status) {
  return useQuery("staff", () => fetchAllStaff(status));
}
