/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import moment from "moment";
import { SideModal, Input, TextArea, Spinner } from "../../common";
import { JobsApi } from "../../api";
import supabase from "../../api/supabase";

export function CreateEditDayWorkTask({ job_id, DayWorkTaskID, open, setOpen }) {
  const createDayWorkTaskMutation = JobsApi.useCreateDayWorkTask();
  const UpdateDayWorkTaskMutation = JobsApi.useUpdateupdateDayWorkTask();
  const createEDinvoiceMutation = JobsApi.useCreateEdInvoice();
  const updateEdInvoiceMutation = JobsApi.useUpdateEdInvoice();
  const [dayWorkTaskData, setDayWorkTaskData] = useState(null);

  useEffect(() => {
    if (DayWorkTaskID && !dayWorkTaskData) {
      JobsApi.fetchDayWorkTaskByID(DayWorkTaskID).then((data) => setDayWorkTaskData(data[0]));
    }
  });

  if (DayWorkTaskID && !dayWorkTaskData) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <div>
      <Formik
        initialValues={{
          Workers: dayWorkTaskData?.Workers || 0,
          total_hours: dayWorkTaskData?.total_hours || 0,
          Rate: dayWorkTaskData?.Rate || 60,
        }}
        onSubmit={async (values, { resetForm }) => {
          const task_type = "Day-Work-Task";
          const zone_label = "Day-Work-Task";
          const { description } = values;
          let { Workers, total_hours, Rate } = values;

          Workers = Number(Workers);
          total_hours = Number(total_hours);
          Rate = Number(Number(Rate).toFixed(2));

          const total_Daywork = Workers * total_hours * Rate;

          let dayWorkTaskPayload = {};

          const created_at = moment().toISOString();

          if (DayWorkTaskID) {
            dayWorkTaskPayload = {
              id: DayWorkTaskID,
              created_at,
              description,
              Workers,
              total_hours,
              Rate,
              total_Daywork,
            };
          } else {
            const lastSerial = await supabase
              .from("job_tasks")
              .select("*")
              .eq("task_type", "Day-Work-Task")
              .match({ job_id });

            dayWorkTaskPayload = {
              task_id: `${1000 + Number(job_id)}-DWS${lastSerial.data.length + 1}`,
              task_type,
              zone_label,
              job_id,
              created_at,
              description,
              Workers,
              total_hours,
              Rate,
              total_Daywork,
            };
          }
          try {
            if (DayWorkTaskID) {
              const EDInvoice = {
                id: dayWorkTaskData?.ed_invoice_id,
                job_id: Number(job_id),
                zone: values.zone || "",
                zone_label: values.zone_label || "",
                type: "DW",
                description: values.description || "",
                ed_total: total_Daywork,
              };
              await updateEdInvoiceMutation.mutateAsync(EDInvoice);
              await UpdateDayWorkTaskMutation.mutateAsync(dayWorkTaskPayload);
            } else {
              const EDInvoice = {
                job_id: Number(job_id),
                zone: values.zone || "",
                zone_label: values.zone_label || "",
                type: "DW",
                description: values.description || "",
                ed_total: total_Daywork,
              };
              const EDResponse = await createEDinvoiceMutation.mutateAsync(EDInvoice);
              await createDayWorkTaskMutation.mutateAsync({
                ...dayWorkTaskPayload,
                ed_invoice_id: EDResponse[0].id,
              });
            }

            setDayWorkTaskData([]);
            resetForm();
            setOpen(false);
          } catch (err) {
            console.log("ERROR CREATING/UPDATING Day Work Task", err);
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <SideModal
            heading={`${!DayWorkTaskID ? "Create new" : "Edit"}`}
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            isLoading={isSubmitting}
            formType={`${!DayWorkTaskID ? "create" : "update"}`}
          >
            <div className="flex items-center">
              <Input
                title="Workers"
                id="Workers"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.Workers}
              />
              <Input
                title="Hours"
                id="total_hours"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.total_hours}
              />
            </div>
            <div>
              <Input
                title="Rate"
                id="Rate"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.Rate}
              />
            </div>
            <div>
              <TextArea
                title="Description"
                id="description"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.description}
              />
            </div>
          </SideModal>
        )}
      </Formik>
    </div>
  );
}
