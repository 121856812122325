/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { SideModal, Input, TextArea, Dropdown, Spinner } from "../../../common";

import { JobsApi, WeeklyHireApi } from "../../../api";

const typeOptions = [
  { value: "Scaffolding", label: "Scaffolding" },
  { value: "Stairs", label: "Stairs" },
  { value: "Roof", label: "Roof" },
  { value: "Propping", label: "Propping" },
  { value: "Edge Protection", label: "Edge Protection" },
  { value: "Shrinkwrap", label: "Shrinkwrap" },
  { value: "Geda 1200", label: "Geda 1200" },
];

export function UpdateTask({ taskId, jobId, open, setOpen, type = "tasks" }) {
  const updateTaskMutation = JobsApi.useUpdateTask();
  const updateHireMutation = WeeklyHireApi.useUpdateHire();
  const updateEdInvoiceMutation = JobsApi.useUpdateEdInvoice();

  const tasksQuery = JobsApi.useFetchTask(taskId);

  if (tasksQuery.isLoading) {
    return <div />;
  }

  if (!tasksQuery.data) return null;

  return (
    <div>
      <Formik
        initialValues={{
          zone: tasksQuery.data.zone || "",
          zone_label: tasksQuery.data.zone_label || "",
          type: tasksQuery.data.type || "",
          description: tasksQuery.data.description || "",
          percentage_complete: tasksQuery.data.percentage_complete || 0,
          total_hours: tasksQuery.data.total_hours || "",
          weekly_hire_rate: tasksQuery.data.weekly_hire_rate || 0,
          total_value: tasksQuery.data.total_value || "",
        }}
        onSubmit={async (values, { resetForm }) => {
          const hours = Number(values.total_hours);
          const fixed = hours.toFixed(2);
          const taskPayload = {
            job_id: Number(jobId),
            zone: values.zone,
            zone_label: values.zone_label,
            type: values.type,
            description: values.description,
            percentage_complete: Number(values.percentage_complete),
            total_hours: String(fixed),
            weekly_hire_rate: values.weekly_hire_rate ? values.weekly_hire_rate : 0,
            total_value: values.total_value ? values.total_value : 0,
          };
          try {
            if (values.weekly_hire_rate && tasksQuery?.data?.weekly_hire_id) {
              const WHInvoice = {
                job_id: Number(jobId),
                zone: values.zone || "",
                zone_label: values.zone_label || "",
                type: values.type || "",
                description: values.description || "",
                weekly_hire_rate: values.weekly_hire_rate,
              };
              await updateHireMutation.mutateAsync({
                hire: WHInvoice,
                hireId: tasksQuery?.data?.weekly_hire_id,
              }); 
            }
            const EDInvoice = {
              id: tasksQuery?.data?.ed_invoice_id,
              job_id: Number(jobId),
              zone: values.zone || "",
              zone_label: values.zone_label || "",
              type: values.type || "",
              description: values.description || "",
              ed_total: values.total_value,
            };// TODO: FIGURE OUT WHY ED INVOICES AREN'T IN DB
            if(tasksQuery?.data?.ed_invoice_id != null)
            {
            await updateEdInvoiceMutation.mutateAsync(EDInvoice);
            }
            await updateTaskMutation.mutateAsync({
              payload: taskPayload,
              taskId,
              type,
            });
            setOpen(false);
            resetForm();
          } catch (err) {
            console.log("ERROR CREATING JOB", err);
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <SideModal
            heading="Update Task"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            isLoading={isSubmitting}
            formType="edit"
          >
            <div className="flex items-center">
              <Input
                title="Zone"
                id="zone"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.zone}
              />
              <Input
                title="Zone Label"
                id="zone_label"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.zone_label}
              />
            </div>
            <div className="w-1/2">
              <Dropdown
                label="Type"
                id="type"
                options={typeOptions}
                value={values.type}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </div>

            <div className="">
              <TextArea
                title="Description"
                id="description"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.description}
              />
            </div>

            <div className="flex items-center">
              <Input
                title="Percentage Complete"
                id="percentage_complete"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.percentage_complete}
              />
              <Input
                title="Total Hours"
                id="total_hours"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.total_hours}
              />
            </div>
            <div className="flex items-center">
              <Input
                title="Weekly Hire Fee"
                id="weekly_hire_rate"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.weekly_hire_rate}
                value={values.weekly_hire_rate}
              />
              <Input
                title="Total Value"
                id="total_value"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.total_value}
              />
            </div>
          </SideModal>
        )}
      </Formik>
    </div>
  );
}
