/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { SideModal, Input, TextArea, Dropdown, Address, DateSelect } from "../../../common";

import { JobsApi, WeeklyHireApi } from "../../../api";
import supabase from "../../../api/supabase";

const typeOptions = [
  { value: "Scaffolding", label: "Scaffolding" },
  { value: "Stairs", label: "Stairs" },
  { value: "Roof", label: "Roof" },
  { value: "Propping", label: "Propping" },
  { value: "Edge Protection", label: "Edge Protection" },
  { value: "Shrinkwrap", label: "Shrinkwrap" },
  { value: "Geda 1200", label: "Geda 1200" },
];

export function CreateVariation({ jobId, open, setOpen }) {
  const createTaskMutation = JobsApi.useCreateVariation();
  const createHireMutation = WeeklyHireApi.useCreateHire();
  const createEDinvoiceMutation = JobsApi.useCreateEdInvoice();

  return (
    <div>
      <Formik
        initialValues={{
          zone: "",
          zone_label: "",
          type: "",
          description: "",
          percentage_complete: 0,
          total_hours: "",
          weekly_hire_rate: 0,
          total_value: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.total_value) {
            errors.total_value = "Total Value is required.";
          }

          return errors;
        }}
        onSubmit={async (values, { resetForm }) => {
          const loggedInuser = await supabase.auth.user();
          const name = loggedInuser?.user_metadata?.name;

          const hours = Number(values.total_hours);
          const fixed = hours.toFixed(2);
          const taskPayload = {
            job_id: Number(jobId),
            task_type: "Variation",
            zone: values.zone,
            zone_label: values.zone_label,
            type: values.type,
            description: values.description,
            percentage_complete: Number(values.percentage_complete),
            total_hours: String(fixed),
            weekly_hire_rate: values.weekly_hire_rate ? values.weekly_hire_rate : 0,
            total_value: values.total_value ? values.total_value : 0,
            created_by: name || "",
          };

          try {
            let WHResponse;
            if (values.weekly_hire_rate) {
              const WHInvoice = {
                job_id: Number(jobId),
                zone: values.zone || "",
                zone_label: values.zone_label || "",
                type: values.type || "",
                description: values.description || "",
                weekly_hire_rate: values.weekly_hire_rate,
              };
              WHResponse = await createHireMutation.mutateAsync(WHInvoice);
            }
            const EDInvoice = {
              job_id: Number(jobId),
              zone: values.zone || "",
              zone_label: values.zone_label || "",
              type: values.type || "",
              description: values.description || "",
              ed_total: values.total_value,
            };
            const EDResponse = await createEDinvoiceMutation.mutateAsync(EDInvoice);
            await createTaskMutation.mutateAsync({
              ...taskPayload,
              weekly_hire_id: WHResponse ? WHResponse[0]?.id : null,
              ed_invoice_id: EDResponse[0]?.id,
            });
            setOpen(false);
            resetForm();
          } catch (err) {
            console.log("ERROR CREATING JOB", err);
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <SideModal
            heading="Create Variation Task"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            isLoading={isSubmitting}
            formType="create"
          >
            <div className="flex items-center">
              <Input
                title="Zone"
                id="zone"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.zone}
              />
              <Input
                title="Zone Label"
                id="zone_label"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.zone_label}
              />
            </div>
            <div className="w-1/2">
              <Dropdown
                label="Type"
                id="type"
                options={typeOptions}
                value={values.type}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </div>

            <div className="">
              <TextArea
                title="Description"
                id="description"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.description}
              />
            </div>

            <div className="flex items-center">
              <Input
                title="Percentage Complete"
                id="percentage_complete"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.percentage_complete}
              />
              <Input
                title="Total Hours"
                id="total_hours"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.total_hours}
              />
            </div>
            <div className="flex items-center">
              <Input
                title="Weekly Hire Fee"
                id="weekly_hire_rate"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.weekly_hire_rate}
                value={values.weekly_hire_rate}
              />
              <Input
                title="Total Value"
                id="total_value"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.total_value}
                error={errors.total_value}
              />
            </div>
          </SideModal>
        )}
      </Formik>
    </div>
  );
}
