import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

import supabase from "../../supabase";
import { useNotificationStore } from "../../../store/notifications";

export async function createJobTasksFromQuote(tasks) {
  const { data, error } = await supabase.from("job_tasks").insert(tasks);

  if (error) throw new Error(error.message);
  await createAppenateTask(data);
  return data;
}

export const useCreateJobTasksFromQuote = () =>
  useMutation((tasks) => createJobTasksFromQuote(tasks), {
    onSuccess: (data) => data,
    onError: (error) => error,
    mutationFn: createJobTasksFromQuote,
  });

async function createAppenateTask(jobs) {
  const tasksPayload = [];

  jobs.map((task) =>
    tasksPayload.push([
      task.id,
      task.job_id || "",
      task.zone || "",
      task.zone_label || "",
      `${task.type} - ${task.description}`,
      task.description || "",
      task.complete || "",
    ]),
  );

  return axios.post("https://northlandscaffolding.herokuapp.com/api/data-sync", {
    id: "b9955206f03d4ccf9b40ae6c0170ae2e",
    data: tasksPayload,
  });
}
