import React, { useEffect, useState } from "react";
import { PlusCircleIcon, PencilAltIcon } from "@heroicons/react/outline";
import moment from "moment";
import { Button, Table, Spinner, Badge } from "../../common";
import { JobsApi } from "../../api";
import { CreateEditDayWorkTask } from './CreateEditDayWorkTask';

export function DayWorkTask({ job_ID }) {
    const [dataQuery, setDataQuery] = useState([]);
    const [loading, setLoading] = useState(true);

    const [open, setOpen] = useState(false);

    const [dayworktaskID, setDayWorkTaskID] = useState(null);

    useEffect(() => {
        if (loading && dataQuery.length === 0) {
            JobsApi.fetchAllDayWorkTask(job_ID).then((data) => {
                setDataQuery(data);
                setLoading(false);
            });
        }
    });

    if (loading && dataQuery.length === 0) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    return (
        <div className="w-full mx-auto mt-8">
            <div>
                <h2 className="text-lg px-8 mb-2 leading-6 font-large text-gray-900 mt-6">
                    Day Work Task
                </h2>
                <div className="px-8 py-2">
                    <Button
                        type="button"
                        variant="primary"
                        onClick={() => { setDayWorkTaskID(null); setOpen(true); }}
                        startIcon={<PlusCircleIcon className="w-4 h-4" />}
                    >
                        Create Day Work Task
                    </Button>
                </div>

                <Table
                    cols={[
                        {
                            Header: "id",
                            accessor: "task_id"
                        },
                        {
                            Header: "Last Updated",
                            accessor: "created_at",
                            Cell: ({ row }) => (
                                `${moment(row.values.created_at).format("MMMM Do YYYY")}`
                            )
                        },
                        {
                            Header: "Description",
                            accessor: "description"
                        },
                        {
                            Header: "Workers",
                            accessor: "Workers"
                        },
                        {
                            Header: "Hours",
                            accessor: "total_hours"
                        },
                        {
                            Header: "Rate",
                            accessor: "Rate",
                            Cell: ({ row }) =>
                                `$${Number(row.values.Rate).toFixed(2)}`
                        },
                        {
                            Header: "Total",
                            accessor: "total_Daywork",
                            Cell: ({ row }) =>
                                `$${Number(row.values.total_Daywork).toFixed(2)}`
                        },
                        {
                            Header: "Photo of slip",
                            accessor: "photo_of_slip",
                            Cell: ({ row }) => (
                                row.values.photo_of_slip ? <a href={row.values.photo_of_slip}>Url</a> : <> </>
                            )
                        },
                        {
                            Header: "Pink slip number",
                            accessor: "pink_slip_number",
                        },
                        {
                            Header: "Edit",
                            accessor: "id",
                            Cell: ({ row }) => (
                                <button
                                    type="button"
                                    onClick={() => {
                                        setDayWorkTaskID(row.values.id);
                                        setOpen(true);
                                    }}
                                >
                                    <PencilAltIcon className="text-gray-600 h-4 w-4" />
                                </button>
                            ),
                        },
                    ]}
                    tableData={dataQuery}
                />

            </div>
            {open ?
                <CreateEditDayWorkTask job_id={job_ID} DayWorkTaskID={dayworktaskID} set={setDayWorkTaskID} open={open} setOpen={setOpen} />
                : <br />}
        </div>
    )
}