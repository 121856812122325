import { useMutation } from "react-query";
import supabase from "../../supabase";

export async function updateImages(images) {
  const { data, error } = await supabase.from("quote_images").upsert(images);

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export const useUpdateImages = () =>
  useMutation((images) => updateImages(images), {
    onSuccess: (data) => data,
    onError: (error) => error,
    mutationFn: updateImages,
  });
