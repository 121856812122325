import axios from "axios";
import React, { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { MapIcon } from "@heroicons/react/outline";

export function Address({
  streetId,
  streetId2,
  cityId,
  postalId,
  suburbId,
  streetVal,
  street2Val,
  cityVal,
  postalVal,
  suburbVal,
  main_origin,
  second_origin,
  handleChange,
  handleBlur,
  setFieldValue,
  displayDistance = false,
}) {
  const googleApiKey = "AIzaSyDilh_PqwMiRf38mynksFmFQDLpaA_pOzQ";

  const [street, setStreet] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [suburb, setSuburb] = useState("");

  const handlePlaceSelect = async (place) => {
    const addressComponents = place.address_components;
    let mainOrigin = "";
    let secondOrigin = "";

    if (displayDistance) {
      const origin = ["3 Pipiwai Road, Kamo, Whangārei 0141", "1188b Bulls Road, Kerikeri"];
      const destination = place.formatted_address;

      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            "X-Goog-Api-Key": googleApiKey,
            "X-Goog-FieldMask":
              "routes.duration,routes.distanceMeters,routes.polyline.encodedPolyline",
          },
        };
        const distances = origin.map(async (origin) => {
          const requestData = {
            origin: {
              address: origin,
            },
            destination: {
              address: destination,
            },
            travelMode: "DRIVE",
            units: "IMPERIAL",
          };
          const response = await axios.post(
            "https://routes.googleapis.com/directions/v2:computeRoutes",
            requestData,
            config,
          );
          return (response.data.routes[0].distanceMeters / 1000).toFixed(1);
        });
        mainOrigin = await distances[0];
        secondOrigin = await distances[1];
      } catch (error) {
        console.error(error);
      }
    }
    let street2 = "";
    let city = "";
    let postalCode = "";
    let suburb = "";

    addressComponents.forEach((component) => {
      const { types } = component;

      if (types.includes("street_number")) {
        if (street2 === "") {
          street2 = component.long_name;
        } else {
          street2 += ` ${component.long_name}`;
        }
      }

      if (types.includes("route")) {
        if (street2 === "") {
          street2 = component.long_name;
        } else {
          street2 += ` ${component.long_name}`;
        }
      }

      if (types.includes("sublocality")) {
        if (suburb === "") {
          suburb = component.long_name;
        } else {
          suburb += `, ${component.long_name}`;
        }
      }

      if (types.includes("locality")) {
        if (city === "") {
          city = component.long_name;
        } else {
          city += `, ${component.long_name}`;
        }
        cityVal = city;
      }

      if (types.includes("postal_code")) {
        postalCode = component.long_name;
      }
    });

    setStreet2(street2);
    setCity(city);
    setPostalCode(postalCode);
    setSuburb(suburb);

    setFieldValue("street2", street2);
    setFieldValue("street", place.formatted_address);
    setFieldValue("postal", postalCode);
    setFieldValue("city", city);
    setFieldValue("suburb", suburb);
    setFieldValue("main_origin", mainOrigin);
    setFieldValue("second_origin", secondOrigin);
  };

  useEffect(() => {
    setStreet(streetVal);
    setStreet2(street2Val);
    setCity(cityVal);
    setPostalCode(postalVal);
    setSuburb(suburbVal);
  }, [streetVal, cityVal, street2Val, postalVal, suburbVal]);

  return (
    <div className="px-4 py-4 mt-10 sm:mt-0">
      <div className="md:grid md:grid-cols-6 md:gap-6">
        <div className="col-span-6">
          <label htmlFor={streetId} className="block text-sm font-medium text-gray-700">
            Street address
          </label>
          <br />

          <Autocomplete
            apiKey={googleApiKey}
            placeholder=""
            style={{ width: "100%" }}
            className="autocomplete"
            onPlaceSelected={(place) => {
              handlePlaceSelect(place);
            }}
            options={{
              types: ["address"],
              componentRestrictions: { country: "nz" },
            }}
            defaultValue={street}
          />
          {displayDistance ? (
            <div className="flex justify-between gap-6 mt-5">
              <div className="w-1/2">
                <div className="flex gap-1">
                  <MapIcon className="h-5 w-5" aria-hidden="true" />
                  <label htmlFor={streetId} className="block text-sm font-medium text-gray-700">
                    3 Pipiwai Road, Kamo, Whangarei
                  </label>
                </div>
                <input
                  type="text"
                  className="block w-full mt-1 text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  value={main_origin ? `${main_origin} KM` : ""}
                  disabled
                />
              </div>
              <div className="w-1/2">
                <div className="flex gap-1">
                  <MapIcon className="h-5 w-5" aria-hidden="true" />
                  <label htmlFor={streetId} className="block text-sm font-medium text-gray-700">
                    1188b Bulls Road, Kerikeri
                  </label>
                </div>

                <input
                  type="text"
                  className="block w-full mt-1 text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  onBlur={handleBlur}
                  value={second_origin ? `${second_origin} KM` : ""}
                  disabled
                />
              </div>
            </div>
          ) : null}
        </div>

        <div className="col-span-3">
          <label htmlFor={streetId2} className="block text-sm font-medium text-gray-700">
            Street
          </label>
          <input
            type="text"
            name={streetId2}
            id={streetId2}
            autoComplete="street-address"
            className="block w-full mt-1 text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            onChange={handleChange}
            onBlur={handleBlur}
            value={street2}
          />
        </div>

        <div className="col-span-3">
          <label htmlFor={streetId2} className="block text-sm font-medium text-gray-700">
            Suburb
          </label>
          <input
            type="text"
            name={suburbId}
            id={suburbId}
            autoComplete="suburb"
            className="block w-full mt-1 text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            onChange={handleChange}
            onBlur={handleBlur}
            value={suburb}
          />
        </div>

        <div className="col-span-6 sm:col-span-6 lg:col-span-4">
          <label htmlFor="city" className="block text-sm font-medium text-gray-700">
            City
          </label>
          <input
            type="text"
            name={cityId}
            id={cityId}
            className="block w-full mt-1 text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            onChange={handleChange}
            onBlur={handleBlur}
            value={city}
          />
        </div>
        <div className="col-span-6 sm:col-span-2 lg:col-span-2">
          <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
            Postal Code
          </label>
          <input
            type="text"
            name={postalId}
            id={postalId}
            autoComplete="postal-code"
            className="block w-full mt-1 text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            onChange={handleChange}
            onBlur={handleBlur}
            value={postalCode}
          />
        </div>
      </div>
    </div>
  );
}
