/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import { pdf } from "@react-pdf/renderer";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { useParams, useHistory } from "react-router-dom";
import { Formik } from "formik";
import clsx from "clsx";
import supabase from "../../api/supabase";

import { ClientForm } from "../Clients";
import { ContactForm } from "../Contacts";
import { Input, TextArea, Dropdown, Address, QuoteTab, Button, Spinner } from "../../common";

import { QuoteLines } from "./QuoteLines";
import { Rates } from "./Rates/Rates";
import { AdditionalItems } from "./AdditionalItems/AdditionalItems";
import { Totals } from "./QuoteTotals";
import { useClients } from "../../api/Clients";
import { useStaff } from "../../api/Staff";
import { useJobs } from "../../api/Jobs";
import { useFetchQuote, useFetchQuoteImages } from "../../api/Quotes";
import { fetchAllContacts } from "../../api/ClientContacts";
import { QuotePhotos } from "./QuotePhotos";

import { useNotificationStore } from "../../store/notifications";

import {
  formatAddons,
  formatQuoteLines,
  formatQuotePortalLines,
  formatPortalAddons,
  formatZones,
  formatRates,
  formatImages,
  tabs,
  zoneOptions,
  quoteRates,
  quoteTerms,
} from "./utils";

import { QuotesApi } from "../../api";

export const quoteTypeOptions = [
  { value: "New", label: "New" },
  { value: "Variation", label: "Variation" },
];

export const clientTypeOptions = [
  { value: "Commercial", label: "Commercial" },
  { value: "Construction", label: "Construction" },
  { value: "Civil", label: "Civil" },
  { value: "Residential", label: "Residential" },
];

const brandingOptions = [{ value: "Northland Scaffolding", label: "Northland Scaffolding" }];

export function QuoteForm({ formType = "create" }) {
  const history = useHistory();
  const [redirectOutput, setRedirectOutput] = useState(false);
  const { addNotification } = useNotificationStore();

  const user = supabase.auth.user();

  const [loggedUser, setLoggedUser] = useState(null);

  // Client Form
  const [clientForm, setClientForm] = useState(false);
  const [clientId, setClientId] = useState(null);

  // Contact Form
  const [contactForm, setContactForm] = useState(false);
  const [contactId, setContactId] = useState(null);

  const [transportRate, setTransportRate] = useState(0);
  const [fetchedQuote, setFetchedQuote] = useState([]);
  const [quoteCreating, setQuoteCreating] = useState(false);

  // Quote Lines
  const [quoteLines, setQuoteLines] = useState([]);
  const [additionalItems, setAdditionalItems] = useState([]);

  const [termValues, setTermValues] = useState({
    standardRate: 60,
    nonStandardRate: 90,
    smallTruck: 150,
    hiabTruck: 250,
  });

  // Quote Zones
  const [zoneValues, setZoneValues] = useState([]);
  const [zoneLabels, setZoneLabels] = useState([]);
  const [zones, setZones] = useState(null);

  // Totals
  const [weekTotal, setWeekTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [transportTotal, setTransportTotal] = useState(0);

  // Curent Quote Tab
  const [currentTab, setCurrentTab] = useState("Zones");

  const [quoteNum, setQuoteNum] = useState(null);
  const [client, setClient] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [rates, setRates] = useState([]);
  const clientData = useClients();
  const staffData = useStaff();
  const jobData = useJobs();

  const { quoteId } = useParams();

  const { data } = useFetchQuoteImages(quoteId);

  useEffect(async () => {
    if (formType === "create") {
      const ratesData = await QuotesApi.fetchRates();
      setRates(ratesData);
    }

    // Generate next quote #
    const quotes = await QuotesApi.getQuoteNum();

    const lastQuoteNum = quotes?.quote_num?.split("-")?.[0];
    const nextNumSeq = lastQuoteNum ? Number(lastQuoteNum) + 1 : 1000;

    setQuoteNum(`${String(nextNumSeq)}-1`);
  }, []);

  useEffect(() => {
    renderClientList();
  }, [clientId]);

  useEffect(async () => {
    if (contactId && clientId) {
      const contactsRes = await fetchAllContacts(clientId);
      let contacts;
      if (contactsRes && contactsRes.length > 0) {
        contacts = contactsRes.map((contact) => ({
          label: contact.name,
          value: contact.id,
        }));
      } else {
        contacts = [];
      }
      setContactList(contacts);
    }
  }, [contactId]);

  useEffect(async () => {
    let isCurrent = true;

    if (quoteId) {
      const quote = await QuotesApi.fetchQuote(quoteId);
      setZones(quote?.quote_zones?.length);

      if (isCurrent) {
        const contactsRes = await fetchAllContacts(quote?.client);
        let contacts;
        if (contactsRes && contactsRes.length > 0) {
          contacts = contactsRes.map((contact) => ({
            label: contact.name,
            value: contact.id,
          }));
        } else {
          contacts = [];
        }

        setContactList(contacts);
        setFetchedQuote(quote);
        setRates(quote.quote_rates);

        const formatQuoteLines = formatQuotePortalLines(quote?.quote_lines);
        const formQuoteAddons = formatPortalAddons(quote?.quote_addons);
        const zoneLineItems = zoneOptions.slice(0, quote?.quote_zones.length);

        const labelsArr = quote?.quote_zones.map((item, index) => ({
          zone_id: item.id,
          id: item.zone_id,
          label: String(item.zone_label).trim(),
        }));

        setZoneLabels(labelsArr);

        setZoneValues(zoneLineItems);
        setQuoteLines(formatQuoteLines);
        setAdditionalItems(formQuoteAddons);
      }
    }

    return () => {
      isCurrent = false;
    };
  }, [quoteId]);

  useEffect(async () => {
    if (client) {
      const contactsRes = await fetchAllContacts(client);
      let contacts;
      if (contactsRes && contactsRes.length > 0) {
        contacts = contactsRes.map((contact) => ({
          label: contact.name,
          value: contact.id,
        }));
      } else {
        contacts = [];
      }
      setContactList(contacts);
    }
  }, [client]);

  useEffect(() => {
    let totalWeek = 0;
    let totalAmount = 0;
    let totalTransort = 0;

    if (quoteLines.length) {
      totalWeek += quoteLines.reduce((acc, curr) => acc + Number(curr.hireFee), 0);
      totalAmount += quoteLines.reduce((acc, curr) => acc + Number(curr.total), 0);
      totalTransort += quoteLines.reduce((acc, curr) => acc + Number(curr.transport), 0);
    }
    if (additionalItems.length) {
      totalWeek += additionalItems.reduce((acc, curr) => acc + Number(curr.hireFee), 0);
      totalAmount += additionalItems.reduce((acc, curr) => acc + Number(curr.totalCost), 0);
    }

    setTotal(totalAmount);
    setWeekTotal(totalWeek);
    setTransportTotal(totalTransort);
  }, [quoteLines, additionalItems]);

  // MUTATIONS
  const updateQuoteMutation = QuotesApi.useUpdateQuote();
  const updateZonesMutation = QuotesApi.useUpdateZones();
  const updateLinesMutation = QuotesApi.useUpdateLines();
  const updateAddonsMutation = QuotesApi.useUpdateAddons();
  const updateRatesMutation = QuotesApi.useUpdateRates();
  const updateImagesMutation = QuotesApi.useUpdateImages();

  const createQuoteMutation = QuotesApi.useCreateQuote();
  const createZonesMutation = QuotesApi.useCreateZones();
  const createLinesMutation = QuotesApi.useCreateLines();
  const createAddonsMutation = QuotesApi.useCreateAddons();
  const createRatesMutation = QuotesApi.useCreateRates();
  const createImagesMutation = QuotesApi.useCreateImages();

  const renderClientList = () => {
    if (clientData && clientData?.data?.length > 0) {
      return clientData.data.map((client) => ({
        label: client.client_name,
        value: client.id,
      }));
    }
    return [];
  };

  const renderStaffList = () => {
    if (staffData && staffData?.data?.length > 0) {
      return staffData.data.map((staff) => ({
        label: staff.staff_name,
        value: staff.id,
        email: staff.email,
      }));
    }
    return [];
  };

  const renderJobList = () => {
    if (jobData && jobData?.data?.length > 0) {
      return jobData.data.map((job) => ({
        label: `${job.id + 1000} - ${job.site}`,
        value: job.id,
      }));
    }
    return [];
  };

  const renderContactList = () => {};

  const handleZoneChange = (val) => {
    const zoneVal = Number(val);

    const labelsArr = Array(zoneVal)
      .fill()
      .map((obj, index) => ({
        zone_id: zoneLabels[index]?.zone_id || "",
        id: index + 1,
        label: zoneLabels[index]?.label || "",
      }));
    setZoneLabels(labelsArr);
    setZones(val);

    const zoneLineItems = zoneOptions.slice(0, zoneVal);
    setZoneValues(zoneLineItems);
  };

  const handleZoneLabelChange = (val, index) => {
    setZoneLabels(
      zoneLabels.map((item) => {
        if (index === Number(item.id)) {
          return { ...item, label: val.target.value };
        }
        return item;
      }),
    );
  };

  const loggedInUser = () => {
    const staffArr = staffData.data.find((staff) => staff.email === user?.email);
    return staffArr?.id;
  };

  if (!rates.length || quoteNum === null) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (quoteId && !fetchedQuote.id)
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  // console.log("fetchedQuote >>> ", fetchedQuote);
  return (
    <div className="mb-10">
      <div className="">
        <Formik
          initialValues={{
            quote_type: "New",
            variation_job_id: fetchedQuote.variation_job_id || "",
            quote_num: formType === "edit" ? `${fetchedQuote.quote_num}` : `${quoteNum}`,
            branding: fetchedQuote.branding || "Northland Scaffolding",
            clientType: fetchedQuote.clientType || "",
            client: fetchedQuote.client || clientId,
            version: fetchedQuote?.version || 1,
            estimator: fetchedQuote?.estimator || loggedInUser(),
            contact: fetchedQuote.contact_id || contactId,
            maxZones: String(fetchedQuote.max_zones) || "",
            description:
              fetchedQuote.description ||
              "Quote is for the supply, installation, dismantle, removal, and weekly hire of scaffolding as per the scope provided ",
            street: fetchedQuote?.street_1 || "",
            street2: fetchedQuote?.street_2 || "",
            city: fetchedQuote?.city || "",
            postal: fetchedQuote.post_code || "",
            transport_total: fetchedQuote || transportRate,
            terms: fetchedQuote?.terms || quoteTerms,
            status: fetchedQuote?.status || "Pending",
            job_name: fetchedQuote?.job_name || "",
            main_origin: fetchedQuote?.main_origin || "",
            second_origin: fetchedQuote?.second_origin || "",
          }}
          validate={(values) => {
            const errors = {};
            // if (!values.quote_num) {
            //   errors.quote_num = "Quote # is required.";
            // }
            // if (!values.client) {
            //   errors.client = "Client is required.";
            // }
            // if (!values.estimator) {
            //   errors.estimator = "Estimator is required.";
            // }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            console.time("CREATING");
            setQuoteCreating(true);
            const {
              quote_type,
              variation_job_id,
              quote_num,
              client,
              version,
              contact,
              estimator,
              clientType,
              maxZones,
              description,
              street,
              street2,
              city,
              postal,
              terms,
              status,
              job_name,
              main_origin,
              second_origin,
            } = values;

            const quotePayload = {
              quote_type,
              variation_job_id: variation_job_id || null,
              quote_num,
              version,
              clientType,
              branding: values.branding || "Northland Scaffolding",
              client: client || null,
              contact_id: contact || null,
              estimator: estimator || null,
              created_by: user?.user_metadata?.name,
              max_zones: maxZones,
              description,
              street_1: street,
              street_2: street2,
              city,
              post_code: postal,
              terms: String(terms),
              transport_total: Number(transportTotal),
              weekly_total: Number(weekTotal) || null,
              total_amount: Number(total) || null,
              status,
              job_name,
              main_origin,
              second_origin,
            };
            if (formType === "edit") {
              try {
                console.time("TIMER");
                const quoteResult = await updateQuoteMutation.mutateAsync(
                  {
                    quote: quotePayload,
                    quoteId: fetchedQuote?.id,
                  },
                  {
                    onSuccess: (payload) => {
                      const zones = formatZones(zoneLabels, fetchedQuote?.id, "edit");
                      const lines = formatQuoteLines(quoteLines, fetchedQuote?.id, "edit");
                      const addons = formatAddons(additionalItems, fetchedQuote?.id, "edit");
                      const formatedRates = formatRates(rates, fetchedQuote?.id, "edit");
                      const formatedImages = formatImages(values, fetchedQuote?.id, "edit", data);
                      updateZonesMutation.mutate(zones, {
                        onSuccess: (payload) => {},
                        onError: (error) => console.log("error", error),
                      });

                      updateLinesMutation.mutate(lines, {
                        onSuccess: (payload) => {},
                        onError: (error) => console.log("error", error),
                      });

                      updateRatesMutation.mutate(formatedRates, {
                        onError: (error) => console.log("error", error),
                      });

                      updateImagesMutation.mutate(formatedImages, {
                        onError: (error) => console.log("error", error),
                      });

                      updateAddonsMutation.mutate(addons, {
                        onSuccess: (payload) => {
                          setQuoteCreating(false);

                          if (redirectOutput) {
                            history.push(`/quotes/${fetchedQuote?.id}/output`);
                          } else {
                            history.push("/quotes");
                          }

                          addNotification({
                            isSuccess: true,
                            heading: "Success!",
                            content: `Successfully updated quote!`,
                          });
                        },
                        onError: (error) => {
                          setQuoteCreating(false);

                          history.push("/quotes");
                          addNotification({
                            isSuccess: false,
                            heading: "Failure!",
                            content: `Failed to update quote. ${error?.message}`,
                          });
                        },
                      });
                    },
                    onError: (error) => console.log("error", error),
                  },
                );
              } catch (err) {
                console.log("error", err);
              }
            } else {
              createQuoteMutation.mutate(quotePayload, {
                onSuccess: async (payload) => {
                  const quoteId = payload?.[0]?.id;

                  // Format payloads
                  const zones = formatZones(zoneLabels, quoteId);
                  const addons = formatAddons(additionalItems, quoteId);
                  const lines = formatQuoteLines(quoteLines, quoteId);
                  const formatedRates = formatRates(rates, quoteId);

                  const formatedImages = formatImages(values, quoteId);

                  // CREATE ZONES
                  await createZonesMutation.mutate(zones, {
                    onError: (error) => console.log("error", error),
                  });

                  // CREATE ZONES
                  await createLinesMutation.mutate(lines, {
                    onError: (error) => console.log("error", error),
                  });

                  await createRatesMutation.mutate(formatedRates, {
                    onError: (error) => console.log("error", error),
                  });

                  // CREATE  QUOTE IMAGES
                  console.log("Imagenes: ", formatedImages);
                  await createImagesMutation.mutate(formatedImages, {
                    onError: (error) => console.log("error", error),
                    onSuccess: () => {},
                  });

                  // CREATE QUOTE ADDONS
                  await createAddonsMutation.mutate(addons, {
                    onSuccess: (payload) => {
                      setQuoteCreating(false);

                      addNotification({
                        isSuccess: true,
                        heading: "Success!",
                        content: `Successfully created quote!`,
                      });
                    },
                    onError: (err) => {
                      setQuoteCreating(false);

                      // history.push("/quotes");
                      addNotification({
                        isSuccess: false,
                        heading: "Failure!",
                        content: `Failed to create quote. ${err?.message}`,
                      });
                    },
                  });

                  // if(  createAddonsMutation.is &&  createRatesMutation.isSuccess) {
                  history.push(`/quotes/${quoteId}/output`);
                  // }
                },
                onError: (err) => {
                  console.log("Error: ", err);
                  setQuoteCreating(false);
                },
              });
            }
            console.timeEnd("CREATING");
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <div className="w-full">
              <div className="flex">
                <form onSubmit={handleSubmit} id="quoteForm" className="w-4/6">
                  <div className="flex">
                    <div className="w-1/2">
                      <Dropdown
                        label="New / Variation"
                        id="quote_type"
                        value={values.quote_type}
                        onChange={(opt, value) => {
                          setFieldValue("quote_type", value);
                          // setClientId(value);
                          // setClient(value);
                        }}
                        onBlur={setFieldTouched}
                        options={quoteTypeOptions}
                      />
                    </div>
                    <div className="w-1/2">
                      {values.quote_type === "Variation" && (
                        <Dropdown
                          label="Job"
                          id="variation_job_id"
                          value={values.variation_job_id}
                          onChange={async (opt, value) => {
                            setFieldValue("variation_job_id", value);

                            // reset field values
                            setFieldValue("client", "");
                            setFieldValue("contact", "");
                            setFieldValue("street", "");
                            setFieldValue("street2", "");
                            setFieldValue("city", "");
                            setFieldValue("postal", "");
                            // Find matching job and quote
                            const jobMatch = jobData.data.find((job) => job.id === value);

                            if (jobMatch && jobMatch.quote_id) {
                              try {
                                const { data, error } = await supabase
                                  .from("quotes")
                                  .select("*")
                                  .eq("id", Number(jobMatch.quote_id));
                                const quote = data?.[0];
                                if (quote) {
                                  const oldQuoteNumber = quote?.quote_num?.split("-");
                                  let { data } = await supabase.from("quotes");
                                  data = data.map((row) => row.quote_num.split("-"));
                                  data = data.filter((e) => oldQuoteNumber[0] === e[0]);

                                  setFieldValue("client", quote?.client);
                                  setFieldValue(
                                    "quote_num",
                                    `${oldQuoteNumber[0]}-V${data.length}`,
                                  );
                                  setClientId(quote?.client);
                                  setClient(quote?.client);
                                  setFieldValue("clientType", quote?.clientType || "");
                                  setFieldValue("maxZones", String(quote?.max_zones) || "");
                                  setFieldValue("contact", quote?.contact_id || "");
                                  setFieldValue("street", quote?.street_1 || "");
                                  setFieldValue("street2", quote?.street_2 || "");
                                  setFieldValue("city", quote?.city || "");
                                  setFieldValue("postal", quote?.post_code || "");
                                  setFieldValue("estimator", quote?.estimator || "");
                                  setFieldValue("job_name", quote?.job_name || "");
                                }
                              } catch (err) {
                                console.log("error", err);
                              }
                            }

                            // setClientId(value);
                            // setClient(value);
                          }}
                          onBlur={setFieldTouched}
                          options={renderJobList()}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/2">
                      <Dropdown
                        label="Select Branding"
                        id="branding"
                        value={values.branding}
                        onChange={(opt, value) => setFieldValue("branding", value)}
                        onBlur={setFieldTouched}
                        options={brandingOptions}
                      />
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label="Select Client Type"
                        id="clientType"
                        value={values.clientType}
                        onChange={(opt, value) => setFieldValue("clientType", value)}
                        onBlur={setFieldTouched}
                        options={clientTypeOptions}
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/2">
                      <Dropdown
                        label="Client"
                        id="client"
                        value={values.client}
                        onChange={(opt, value) => {
                          setFieldValue("client", value);
                          setClientId(value);
                          setClient(value);
                        }}
                        onBlur={setFieldTouched}
                        isLoading={clientData.isFetching}
                        options={renderClientList()}
                        // error={errors.client}
                      />
                      {formType !== "edit" && (
                        <div className="flex items-center pl-4">
                          <PlusCircleIcon className="w-6 h-6 text-indigo-500" />
                          <button
                            type="button"
                            className="pl-1 font-semibold leading-5 text-sm text-gray-600 hover:text-gray-800"
                            onClick={() => setClientForm(true)}
                          >
                            Add New Client
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label="Max Zones"
                        id="maxZones"
                        value={values.maxZones}
                        onChange={(opt, value) => {
                          setFieldValue("maxZones", value);
                          handleZoneChange(value);
                        }}
                        // onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        options={zoneOptions}
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/2">
                      <Input
                        title="Quote #"
                        id="quote_num"
                        type="text"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.quote_num}
                        // error={errors.quote_num}
                      />
                    </div>

                    <div className="w-1/2">
                      <Dropdown
                        label="Contact"
                        id="contact"
                        value={values.contact}
                        onChange={(opt, value) => {
                          setContactId(value);
                          setFieldValue("contact", value);
                        }}
                        onBlur={setFieldTouched}
                        options={contactList}
                      />
                      {formType !== "edit" && (
                        <div className="flex items-center pl-4">
                          <PlusCircleIcon
                            className={clsx(
                              !clientId ? "text-indigo-100" : "text-indigo-500",
                              "w-6 h-6",
                            )}
                          />
                          <button
                            type="button"
                            className={clsx(
                              !clientId
                                ? "text-gray-200 cursor-none"
                                : "text-gray-600 hover:text-gray-800",
                              "pl-1 font-semibold leading-5 text-sm",
                            )}
                            onClick={() => setContactForm(true)}
                            disabled={!clientId}
                          >
                            Add New Contact
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <TextArea
                    title="Description"
                    id="description"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.description}
                  />
                  <div className="flex">
                    <div className="w-1/2">
                      <Dropdown
                        label="Estimator"
                        id="estimator"
                        // filterByEmail={values.estimator === user?.email}
                        value={values.estimator}
                        onChange={(opt, value) => {
                          setFieldValue("estimator", value);
                        }}
                        onBlur={setFieldTouched}
                        isLoading={staffData.isFetching}
                        options={renderStaffList()}
                        // error={errors.estimator}
                      />
                    </div>
                    <div className="w-1/2">
                      <Input
                        title="Job Name"
                        id="job_name"
                        type="text"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.job_name}
                      />
                    </div>
                  </div>
                  <h3 className="text-lg px-4 leading-6 font-large">Site Address</h3>
                  <Address
                    streetId="street"
                    streetId2="street2"
                    cityId="city"
                    postalId="postal"
                    streetVal={values.street}
                    street2Val={values.street2}
                    cityVal={values.city}
                    postalVal={values.postal}
                    main_origin={values.main_origin}
                    second_origin={values.second_origin}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    displayDistance
                  />
                </form>
                <div className="w-full">
                  <div className="w-4/6 mx-auto">
                    <div className="flex justify-start mb-4">
                      <QuoteTab tabs={tabs} handleChange={(tab) => setCurrentTab(tab)} />
                    </div>
                    {currentTab === "Zones" ? (
                      <>
                        {zones ? (
                          <div className="grid grid-cols-2">
                            {[...Array(Number(zones))].map((_, index) => (
                              <div className="flex items-center" key={index}>
                                <Input
                                  id={index + 1}
                                  title={`Zone ${index + 1}`}
                                  labelInline
                                  placeholder={`Zone ${index + 1}`}
                                  defaultValue={
                                    formType === "edit"
                                      ? fetchedQuote?.quote_zones?.[index]?.zone_label
                                      : ""
                                  }
                                  handleChange={(val) => handleZoneLabelChange(val, index + 1)}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div>
                            <p>Select # of zones to continue</p>
                          </div>
                        )}
                      </>
                    ) : (
                      <Rates
                        data={rates}
                        setRates={setRates}
                        user={user}
                        handleChange={(data) => {
                          const updatedTerms = formatRateText(data);
                          console.log("CHANGeD!!!! ", updatedTerms);
                          setFieldValue("terms", updatedTerms);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="px-4">
                <QuoteLines
                  quoteLines={quoteLines}
                  setQuoteLines={setQuoteLines}
                  zoneOptions={zoneValues}
                  zoneLabels={zoneLabels}
                  rates={rates}
                  formType={formType}
                />
                <AdditionalItems
                  items={additionalItems}
                  setItems={setAdditionalItems}
                  rates={rates}
                  formType={formType}
                />
              </div>
              <div className="px-4">
                <QuotePhotos
                  setFieldValue={setFieldValue}
                  data={formType !== "create" ? data : []}
                />
                <br />
              </div>

              <div className="w-3/6">
                <TextArea
                  title="Terms & Conditions"
                  id="terms"
                  type="text"
                  handleChange={handleChange}
                  value={values.terms}
                  rows={10}
                />
              </div>
              <Totals weekTotal={weekTotal} total={total} transportTotal={transportTotal} />
            </div>
          )}
        </Formik>
      </div>
      <div className="pl-4 mt-6">
        {formType === "edit" ? (
          <div className="flex space-x-4">
            <Button
              type="submit"
              form="quoteForm"
              isLoading={quoteCreating && !redirectOutput}
              size="sm"
            >
              Save & Exit
            </Button>
            <Button
              type="submit"
              form="quoteForm"
              isLoading={quoteCreating && redirectOutput}
              size="sm"
              onClick={() => setRedirectOutput(true)}
            >
              Save & View
            </Button>
          </div>
        ) : (
          <Button type="submit" form="quoteForm" isLoading={quoteCreating} size="sm">
            Create Quote
          </Button>
        )}
      </div>
      <ClientForm
        heading="Create Client"
        open={clientForm}
        setOpen={setClientForm}
        setClientId={setClientId}
      />
      <ContactForm
        heading="Create Contact"
        open={contactForm}
        setOpen={setContactForm}
        setContactId={setContactId}
      />
    </div>
  );
}

function formatRateText(rates) {
  console.log("RATES >>> ", rates);
  const standardHireRate = rates[8].erect_fee;
  const nonStandardHireRate = rates[9].erect_fee;
  const smallTruckFee = rates[10].erect_fee;
  const hiabTruckFee = rates[11].erect_fee;
  return `-All Scaffolding to comply with Worksafe Good Practice Guidelines for Scaffolding in New Zealand
  Quote is valid for 3 months following date issued.
  Quotes accepted outside of the 90-day period may be reviewed before proceeding with the works.
  Asbestos
  Risk of exposure to asbestos building material must be disclosed to Northland Scaffolding staff. Equipment must be washed down following exposure to asbestos dust. Failing to disclose asbestos risks or wash down equipment may incur additional charges for RPE Protective Equipment and Labour.
  Safety Inspections
  Northland Scaffolding may make site visits to conduct safety inspections and resign Safe Tags. Site visits will incur a minimum charge of $50.00 per visit. Additional charges may be incurred for alterations or reinstatement of scaffolding. Worksafe expect Hanging Scaffolds, Roof structures and Scaffolding over 5m high be inspected weekly.
  Additional Charges to Quote
  Standard travel charges are included in the quoted price. Travel charges may be applied if equipment is requested by the client (or clients representative) to be erected or dismantled in multiple stages, requiring additional site visits.
  Any contract works which are delayed due to other trades or the site area where the scaffold is to be built not offering unrestricted access, additional labour charges will apply.
  Over head Power lines
  Power lines may need to be disconnected or sleeved if scaffolding will be erected within 4m of lines. The owner of the building should organise lines to be sleeved. Sleeving can be arranged with Whangarei Electrical Services (WES). Additional charges may apply if Northland Scaffolding need to make these arrangements.
  Alterations and damage to equipment
  Alterations to equipment, such as lowering of working platforms or erecting of extra equipment, will be charged in addition to this quote unless otherwise stated. Hire charges will be reduced or increased to reflect quantity of equipment on site.
  If Scaffolding gear is damaged due to the improper use or negligence by the contractor, or any other trades, the Contractor will be charged for the replacement cost of the damaged gear.
  Hire periods
  Hire periods are calculated from the day the equipment is ready for use, until the day the customer ends the hire period. Hire periods are not prearranged, the customer must notify Northland Scaffolding via email to offhire@northlandscaffolding.co.nz when ready to end a hire period.
  Scaffolding and Temporary Fencing - 1 Week minimum hire.
  Safety netting - Quote includes up to 1 months Hire, a hire charge of 10% of the quoted price will be applied per week after the first month.
  Weekly Hire During Covid-19 Alert levels
  Weekly hire charges will remain the same at alert levels 1, 2 and 3. In the event of an alert level 4 (lockdown) weekly hire may be reduced upto 50% if the equipment is not able to be used during alert level 4 or is not essential to a sites security/water proofing. Roof structure scaffolding and temporary fencing will not have a reduction in hireage.
  Engineering and Design
  Northland Scaffolding may make use of external Engineers for advanced scaffolding (ie roof structures, hanging scaffolds) The costs associated with these designs and engineers approval may be in addition to this quote unless otherwise stated. Significant changes to design scope may require additional engineering.  
  Progress Invoicing
  Invoicing for account holders will be issued monthly for hireage and works undertaken that month. If works span between an end of month period a partial progress invoice may be issued for works delivered that month, including hireage for equipment that is able to be used.
  `;
}
