import { v4 as uuidv4 } from "uuid";

export function formatQuoteLines(lines, quoteId, type = "") {
  return lines.map((line) => ({
    ...(type === "edit" && { id: line.id || null }),
    quote_id: quoteId,
    zone: line.zone,
    zone_label: line.zoneLabel,
    type: line.type,
    description: line.description,
    quantity: line.quantity,
    length: line.lengthMeasurement,
    height: line.height,
    width: line.width,
    total_dimensions: line.totalDimensions || 0,
    weekly_duration: line.duration || 0,
    transport: line.transport || 0,
    erect_dismantle: line.dismantle,
    weekly_fee: line.hireFee || 0,
    total: String(line.total) || "",
  }));
}

export function formatQuotePortalLines(lines, quoteId) {
  return lines.map((item) => ({
    id: item.id,
    zone: String(item.zone).trim(),
    zoneLabel: item.zone_label,
    type: item.type,
    description: item.description,
    quantity: item.quantity || 1,
    lengthMeasurement: item.length,
    height: item.height,
    width: item.width,
    totalDimensions: item.total_dimensions || 0,
    duration: item.weekly_duration || 0,
    transport: item.transport || 0,
    dismantle: item.erect_dismantle,
    hireFee: item.weekly_fee || 0,
    total: item.total || 0,
  }));
}

export function formatZones(zones, quoteId, type = "") {
  return zones.map((zone) => ({
    ...(type === "edit" && { id: zone.zone_id || null }),
    quote: quoteId,
    zone_id: zone.id,
    zone_label: zone.label,
  }));
}

export function formatAddons(addons, quoteId) {
  return addons.map((addon) => ({
    id: addon.id,
    quote: quoteId,
    type: addon.type,
    description: addon.description,
    duration: Number(addon.duration) || null,
    hire_fee: Number(addon.hireFee) || null,
    fixed_charge: Number(addon.fixedCharge) || null,
    total: Number(addon.totalCost) || null,
  }));
}

export function formatRates(rates, quoteId, type = "create") {
  return rates.map((rate) => ({
    ...(type === "edit" && { id: rate.id || null }),
    quote_id: quoteId,
    service: rate.service,
    erect_fee: rate.erect_fee,
    hire_fee: rate.hire_fee,
    type: rate.type,
  }));
}

export function formatPortalAddons(addons) {
  return addons.map((addon) => ({
    id: addon.id,
    type: addon.type,
    description: addon.description,
    duration: addon.duration,
    hireFee: addon.hire_fee,
    fixedCharge: addon.fixed_charge,
    totalCost: Number(addon.total),
  }));
}

export function formatImages(values, quoteId, type = "create", data) {
  const formatedImages = [];

  for (let i = 1; i <= 8; i++) {
    const photoField = `quote_photo_${i}`;
    const idField = `id`;
    const positionField = `quote_photo_${i}`;

    if (values[photoField]) {
      const image = {
        ...(type === "edit" && { [idField]: data[i - 1]?.id }),
        quote_id: quoteId,
        link: values[photoField],
        position: positionField,
      };

      formatedImages.push(image);
    }
  }

  return formatedImages;
}

export const tabs = [
  { name: "Zones", href: "#", id: 0 },
  { name: "Rates", href: "#", id: 1 },
];

export const zoneOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
];

export const quoteRates = [
  {
    id: uuidv4(),
    service: "Roof",
    erect: "95.00",
    hire: "12.00",
  },
  {
    id: uuidv4(),
    service: "Propping",
    erect: "22.00",
    hire: "2.00",
  },
  {
    service: "Edge Protection",
    erect: "28.00",
    hire: "0.70",
  },
  {
    service: "Shrinkwrap",
    erect: "10.00",
    hire: "0.00",
  },
  {
    service: "Geda 1200",
    erect: "320.00",
    hire: "5.00",
  },
];

export const quoteTerms = `All Scaffolding to comply with Worksafe Good Practice Guidelines for Scaffolding in New Zealand
Quote is valid for 3 months following date issued.
Quotes accepted outside of the 90-day period may be reviewed before proceeding with the works.
Asbestos
Risk of exposure to asbestos building material must be disclosed to Northland Scaffolding staff. Equipment must be washed down following exposure to asbestos dust. Failing to disclose asbestos risks or wash down equipment may incur additional charges for RPE Protective Equipment and Labour.
Safety Inspections
Northland Scaffolding may make site visits to conduct safety inspections and resign Safe Tags. Site visits will incur a minimum charge of $50.00 per visit. Additional charges may be incurred for alterations or reinstatement of scaffolding. Worksafe expect Hanging Scaffolds, Roof structures and Scaffolding over 5m high be inspected weekly.
Additional Charges to Quote
Standard travel charges are included in the quoted price. Travel charges may be applied if equipment is requested by the client (or clients representative) to be erected or dismantled in multiple stages, requiring additional site visits.
Any contract works which are delayed due to other trades or the site area where the scaffold is to be built not offering unrestricted access, additional labour charges will apply.
Over head Power lines
Power lines may need to be disconnected or sleeved if scaffolding will be erected within 4m of lines. The owner of the building should organise lines to be sleeved. Sleeving can be arranged with Whangarei Electrical Services (WES). Additional charges may apply if Northland Scaffolding need to make these arrangements.
Alterations and damage to equipment
Alterations to equipment, such as lowering of working platforms or erecting of extra equipment, will be charged in addition to this quote unless otherwise stated. Hire charges will be reduced or increased to reflect quantity of equipment on site.
If Scaffolding gear is damaged due to the improper use or negligence by the contractor, or any other trades, the Contractor will be charged for the replacement cost of the damaged gear.
Hire periods
Hire periods are calculated from the day the equipment is ready for use, until the day the customer ends the hire period. Hire periods are not prearranged, the customer must notify Northland Scaffolding via email to offhire@northlandscaffolding.co.nz when ready to end a hire period.
Scaffolding and Temporary Fencing - 1 Week minimum hire.
Safety netting - Quote includes up to 1 months Hire, a hire charge of 10% of the quoted price will be applied per week after the first month.
Weekly Hire During Covid-19 Alert levels
Weekly hire charges will remain the same at alert levels 1, 2 and 3. In the event of an alert level 4 (lockdown) weekly hire may be reduced upto 50% if the equipment is not able to be used during alert level 4 or is not essential to a sites security/water proofing. Roof structure scaffolding and temporary fencing will not have a reduction in hireage.
Engineering and Design
Northland Scaffolding may make use of external Engineers for advanced scaffolding (ie roof structures, hanging scaffolds) The costs associated with these designs and engineers approval may be in addition to this quote unless otherwise stated. Significant changes to design scope may require additional engineering.  
Progress Invoicing
Invoicing for account holders will be issued monthly for hireage and works undertaken that month. If works span between an end of month period a partial progress invoice may be issued for works delivered that month, including hireage for equipment that is able to be used.
`;
