import { useQuery } from "react-query";
import supabase from "../../supabase";

export async function fetchQuoteImages(quote_id) {
  let query = supabase.from("quote_images").select("*").order("id", { ascending: true });
  if (quote_id) {
    query = query.match({ quote_id });
  }
  const { data, error } = await query;
  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export function useFetchQuoteImages(quote_id) {
  return useQuery("quote_images", () => fetchQuoteImages(quote_id));
}
