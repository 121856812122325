import { CheckIcon } from "@heroicons/react/outline";
import { useState } from "react";
import moment from "moment";
import { Input, TextArea, Dropdown, Address, Tabs, Button, ConfirmationDialog } from "../../common";
import { QuotesApi, WeeklyHireApi, JobsApi } from "../../api";
import supabase from "../../api/supabase";

export const ApproveQuote = ({ quoteId, status, quotePayload }) => {
  const user = supabase.auth.user();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    approvedBy: user?.user_metadata?.name || "",
    clientApproved: "",
    description: "",
    status: "Approved",
  });
  console.log("quotePayloadquotePayload", quotePayload);
  const updateApproveStatus = QuotesApi.useUpdateQuoteStatus(quoteId, state);
  const updateJobMutation = JobsApi.useUpdateJob();
  const createJobFromQuote = QuotesApi.useCreateJobFromQuote();
  const createJobTasksFromQuote = QuotesApi.useCreateJobTasksFromQuote();
  const createHireInvoices = WeeklyHireApi.useCreateHire();
  const createEDinvoiceMutation = JobsApi.useCreateEdInvoice();
  const updateImagesMutation = QuotesApi.useUpdateImages();

  const handleInputChange = (event) => {
    const { id, value } = event.target;

    setState({
      ...state,
      [id]: value,
    });
  };

  return (
    <ConfirmationDialog
      isDone={createJobTasksFromQuote.isSuccess}
      icon="info"
      title="Approve Quote"
      body="Are you sure you wish to approve this quote? This action will create a job with a list of tasks."
      triggerButton={
        <button
          type="button"
          id={quoteId}
          className={
            status !== "Approved" && status !== "Rejected"
              ? `ml-3 inline-flex items-center text-sm font-medium focus:outline-none hover:text-green-400`
              : "ml-3 inline-flex items-center text-sm text-gray-200"
          }
          disabled={status === "Approved" || status === "Rejected"}
        >
          <CheckIcon
            className={
              status !== "Approved" && status !== "Rejected"
                ? "-ml-0.5 mr-2 h-4 w-4 text-green-400"
                : "-ml-0.5 mr-2 h-4 w-4 text-green-100"
            }
            aria-hidden="true"
          />
          Approve
        </button>
      }
      confirmButton={
        <Button
          isLoading={isLoading}
          variant="approve"
          onClick={async (e) => {
            if (quotePayload.quote_type === "Variation" && quotePayload.variation_job_id) {
              setIsLoading(true);

              // update jobname

              await updateJobMutation.mutateAsync({
                job: { job_name: quotePayload?.job_name },
                jobId: quotePayload.variation_job_id,
              });

              // Just create tasks
              const quoteLines = quotePayload?.quote_lines;
              const formatTaskPayload = quoteLinesToJobTasks(
                quoteLines,
                quotePayload.variation_job_id,
                "Variation",
                quotePayload.id,
              );
              let taskCreated = [];
              try {
                taskCreated = await createJobTasksFromQuote.mutateAsync(formatTaskPayload);
              } catch (err) {
                console.log("ERROR CREATING TASKS", err);
              }
              const formatInvoices = quoteLinesToWeeklyInvoices(
                quoteLines,
                quotePayload.variation_job_id,
                taskCreated,
              );
              try {
                await createHireInvoices.mutateAsync(formatInvoices);
              } catch (err) {
                console.log("ERROR CREATING HIRE INVOICES", err);
              }
              try {
                await updateApproveStatus.mutateAsync();
              } catch (err) {
                console.log("ERROR UPDATING STATUS", err);
              }
              setIsLoading(false);
            } else {
              setIsLoading(true);
              const fields = [
                [quotePayload.street_2],
                [quotePayload.suburb],
                [quotePayload.city],
                [quotePayload.post_code],
              ];
              const addressFormat = fields
                .map((part) => part.filter(Boolean).join(" "))
                .filter((str) => str.length)
                .join(", ");
              const jobPayload = {
                client_id: quotePayload?.client,
                site: addressFormat || "",
                quote_id: quoteId || null,
                start_date: moment().format("DD/MM/YYYY"),
                end_date: moment().add(3, "M").format("DD/MM/YYYY"),
                job_status: "Pending Handover",
                status: "Active",
                branding: quotePayload?.branding,
                clientType: quotePayload?.clientType,
                job_name: quotePayload?.job_name,
              };
              try {
                await updateApproveStatus.mutateAsync();
                const createdJob = await createJobFromQuote.mutateAsync(jobPayload);

                if (createdJob?.id) {
                  quotePayload?.quote_images.map(async (image) => {
                    await updateImagesMutation.mutate(
                      { ...image, job_id: createdJob.id },
                      {
                        onError: (error) => console.log("error", error),
                      },
                    );
                  });
                }

                const quoteLines = quotePayload?.quote_lines;
                if (quoteLines?.length && createdJob?.id) {
                  const formatTaskPayload = quoteLinesToJobTasks(quoteLines, createdJob.id);

                  const taskCreated = await createJobTasksFromQuote.mutateAsync(formatTaskPayload);

                  const formatInvoices = quoteLinesToWeeklyInvoices(
                    quoteLines,
                    createdJob.id,
                    taskCreated,
                  );

                  await createHireInvoices.mutateAsync(formatInvoices);

                  const formatEDInvoices = quoteLinesToEDInvoices(
                    quoteLines,
                    createdJob.id,
                    taskCreated,
                  );

                  await createEDinvoiceMutation.mutateAsync(formatEDInvoices);
                }
                setIsLoading(false);
              } catch (err) {
                console.log("ERROR ", err);
              }
            }
          }}
        >
          Approve
        </Button>
      }
    >
      <div className="flex">
        <div className="w-1/2">
          <Input
            title="Approved By"
            id="approvedBy"
            type="text"
            value={state.approvedBy}
            handleChange={handleInputChange}
          />
        </div>
        <div className="w-1/2">
          <Input
            title="Client Who Approved"
            id="clientApproved"
            type="text"
            value={state.clientApproved}
            handleChange={handleInputChange}
          />
        </div>
      </div>
      <TextArea
        title="Confirmation Text"
        id="description"
        type="text"
        value={state.description}
        handleChange={handleInputChange}
      />
    </ConfirmationDialog>
  );
};

function quoteLinesToJobTasks(lines, jobId, type, quoteId) {
  if (!type) {
    type = "New";
  }
  console.log("lines", lines);
  const linesResult = [];
  for (let i = 0; i < lines.length; i++) {
    const quantity = Number(lines[i].quantity) || 1;
    for (let j = 0; j < quantity; j++) {
      linesResult.push({
        job_id: jobId,
        task_type: type,
        zone: lines[i].zone,
        zone_label: lines[i].zone_label,
        type: lines[i].type,
        description: lines[i].description,
        total_hours: (lines[i].erect_dismantle / 60).toFixed(2),
        variation_quote_id: quoteId,
      });
    }
  }
  return linesResult;
}

function quoteLinesToWeeklyInvoices(lines, jobId, taskCreated) {
  console.log("lines", lines);
  const linesResult = [];
  for (let i = 0; i < lines.length; i++) {
    const quantity = Number(lines[i].quantity) || 1;
    for (let j = 0; j < quantity; j++) {
      linesResult.push({
        job_id: jobId,
        zone: lines[i].zone,
        zone_label: lines[i].zone_label,
        type: lines[i].type,
        description: lines[i].description,
        weekly_hire_rate: lines[i].weekly_fee,
        task_id: taskCreated[j]?.id || 0,
      });
    }
  }
  return linesResult;
}

function quoteLinesToEDInvoices(lines, jobId, taskCreated) {
  const edinvoicesResult = [];
  for (let i = 0; i < lines.length; i++) {
    const quantity = Number(lines[i].quantity) || 1;
    for (let j = 0; j < quantity; j++) {
      edinvoicesResult.push({
        job_id: jobId,
        zone: lines[i].zone,
        zone_label: lines[i].zone_label,
        type: lines[i].type,
        description: lines[i].description,
        erect: Number((Number(lines[i].erect_dismantle) * 0.7).toFixed(2)),
        dismantle: Number((Number(lines[i].erect_dismantle) * 0.3).toFixed(2)),
        ed_total: Number(lines[i].erect_dismantle),
      });
    }
  }
  return edinvoicesResult;
}
